<template>
    <div class="group-savings-table">
        <!-- Main content -->
        <section class="savings">
        <!-- Default box -->
           <div class="card card-outline card-info row">
                <div class="card-body">
                    <table id="example1" class="table table-striped">
                    <thead class="table-custom-header">
                    <tr>
                        <th>Name</th>
                        <th>Account#</th>
                        <th>Product</th>
                        <th>Ledger Balance</th>
                        <th>Last Transaction</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                         <td></td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        <!-- /.card -->
        </section>
        <!-- /.content -->
    
    </div>
    </template>
    
    <script>
    export default {
       data() {
            return {
                items:[]
            }
        },
    
        created(){
            this.borrowerGroupList()
        },
        methods: {
            async borrowerGroupList(){
                let response = await axios.get('api/borrowerGroup')
                this.items = response.data
            },
        },
    }
    </script>