import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'


import USER_AUTH from './auth.js'

Vue.use(Vuex)
Vue.use(Vuex, axios)

export default new Vuex.Store({
  modules: {
    USER_AUTH
  }
})
