/* ------------ Global Components -------------- */
import AppNavBar from '../components/partials/AppNavBar'
import AppSideBar from '../components/partials/AppSideBar'
import AppFooter from '../components/partials/AppFooter'

import groupCommentsTable from '../components/group-comments-table'
import groupOpenLoanTable from '../components/group-open-loan-table'
import groupPendingDuesTable from '../components/group-pending-dues-table'
import groupRepaymentTable from '../components/group-repayment-table'
import groupSavingsTable from '../components/group-savings-table'
import groupScheduleTable from '../components/group-schedule-table'


function setupComponents(Vue){
    Vue.component('AppNavBar', AppNavBar)
    Vue.component('AppSideBar', AppSideBar)
    Vue.component('AppFooter', AppFooter)
    Vue.component('groupCommentsTable', groupCommentsTable)
    Vue.component('groupOpenLoanTable', groupOpenLoanTable)
    Vue.component('groupPendingDuesTable', groupPendingDuesTable)
    Vue.component('groupRepaymentTable', groupRepaymentTable)
    Vue.component('groupSavingsTable', groupSavingsTable)
    Vue.component('groupScheduleTable', groupScheduleTable)
}

export {
    setupComponents
  }
  