<template>
    <div class="group-pending-dues-table">
        <!-- Main content -->
        <section class="peding-dues">
        <!-- Default box -->
        <div class="card card-outline mt-3 card-info">
            <div class="card-body">
                <table id="example1" class="table table-striped">
                <thead class="table-custom-header">
                <tr>
                    <th width="400">Based on Loan Terms:</th>
                    <th style="text-align:right" width="200">Principal</th>
                    <th style="text-align:right" width="200">Interest</th>
                    <th style="text-align:right" width="200">Penalty</th>
                    <th style="text-align:right" width="200">Total</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><button class="btn btn-danger btn-sm w-50">Total Due </button></td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                    </tr>
                    <tr>
                        <td><button class="btn btn-success btn-sm w-50">Total Paid </button></td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                    </tr>
                     <tr>
                        <td><button class="btn btn-dark btn-sm w-50">Balance </button></td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                    </tr>
                </tbody>
                </table>
                <table id="example1" class="table table-striped">
                <thead class="table-custom-header">
                <tr>
                    <th width="400">Based on Loan Schedule:</th>
                    <th width="200"></th>
                    <th width="200"></th>
                    <th width="200"></th>
                    <th width="200"></th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><button class="btn btn-danger btn-sm w-50">Due till 06/06/2024  </button></td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                    </tr>
                    <tr>
                        <td><button class="btn btn-success btn-sm w-50">Paid till 06/06/2024  </button></td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                    </tr>
                     <tr>
                        <td><button class="btn btn-dark btn-sm w-50">Balance till 06/06/2024  </button></td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                    </tr>
                </tbody>
                </table>
            </div>
        </div>
        <!-- /.card -->
        </section>
        <!-- /.content -->
    
    </div>
    </template>
    
    <script>
    export default {
       data() {
            return {
                items:[]
            }
        },
    
        created(){
            this.borrowerGroupList()
        },
        methods: {
            async borrowerGroupList(){
                let response = await axios.get('api/borrowerGroup')
                this.items = response.data
            },
        },
    }
    </script>