/* eslint-disable */
const auth = {  
    namespaced:true,    

    state: {
        isLoggedIn: !!localStorage.getItem('token'),
        user:[],
        permission:[],
        settings:[]
    },

    getters: {},

    actions: {

        GET_SETTINGS: async ({commit}) => {
            let { data } = await axios.get(`api/account/settings`)
            commit('SET_SETTINGS', data)
        },

        GET_USER: async ({commit}) => {
            let { data } = await axios.get(`api/auth/user`)
            commit('SET_USER', data)
        },

        GET_PERMISSION: async ({commit}) => {
          
            let { data } = await axios.get(`api/auth/user/permission`)
            commit('SET_PERMISSION', data)
          
        },
    },

    mutations: {

        SET_SETTINGS: (state, payload) => {
            state.settings = payload
        },

        SET_PERMISSION: (state, payload) => {
            state.permission = payload
        },

        SET_USER: (state, payload) => {
            state.user = payload
        },

        LOGIN_USER(state){
            state.isLoggedIn = true
        },

        LOGOUT_USER(state){
            state.isLoggedIn = false
        }
    }
}

export default auth