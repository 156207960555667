import Vue from 'vue' 
import  VueRouter from 'vue-router'
import App from './App.vue'
import routes from './router'
import store from './store'


Vue.config.productionTip = false

import './config/setup-plugins'
import { setupComponents } from './config/setup-components'

import VueSweetAlert from 'vue-sweetalert'

// Bootstrap Vue
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'

//Vue Mask
import VueTheMask from 'vue-the-mask'

// Vue Mask Money
import money from 'v-money'

import FullCalendar from 'vue-full-calendar';
import 'fullcalendar/dist/fullcalendar.css';


/*
|----------------------------------------------------------------------
| VUE INSTANCES
|----------------------------------------------------------------------
*/
setupComponents(Vue);

//Store
Vue.use(store)

// Input Mask
Vue.use(VueTheMask)

// full calendar
Vue.use(FullCalendar); 

Vue.use(money, {precision: 2, prefix: 'R$ '})

// use in routing
Vue.use(VueRouter)

//Sweet Alert
Vue.use(VueSweetAlert)

// Bootstrap vue 
Vue.use(BootstrapVue)


const router = new  VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/*
|----------------------------------------------------------------------
| GLOBAL SETUP
|----------------------------------------------------------------------
*/ 
window.store = require('./store');
window.axios = require('axios');
window.$ = require('jquery');
//window.axios.defaults.baseURL = "http://localhost:8000/";
window.axios.defaults.baseURL = "https://loan-api.blueprintbusinesssolutionscorp.com/";
window.moment = require('moment-timezone/builds/moment-timezone-with-data.min');

/*
|----------------------------------------------------------------------
|  GLOBAL TEXT & DATE FORMATING 
|----------------------------------------------------------------------
*/  

//Upercase Text
Vue.filter('uppercase', function (value) {
  if (!value) return '';
	return value.toUpperCase()
})

//Capitalize Text
Vue.filter('capitalize',function (value){
    if (!value) return '';
    value = value.toString();
    return value.replace(/\w\S*/g, function (txt) {
       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
})

//Format Date
Vue.prototype.getDate = function(date){
  return moment(date).format('MMM DD YYYY');
}

//Format Phone Number
Vue.prototype.formatNumber = function(value){
 if (!value) return 'No Phone Number';
 let num = '32' + value
 return num.replace( /(\d{2})(\d{4})(\d{2})/, 
  '$1-$2-$3' )
}

//Format Mobile Number
Vue.prototype.formatMobile = function(value){
  if (!value) return 'No Mobile Number';
  let num = value
  return num.replace( /(\d{4})(\d{4})(\d{3})/, 
   '$1-$2-$3' )
 }

// Format Currency
Vue.prototype.currency = function(value){
 return new Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency: 'PHP',
  }).format(value)
}



/*
|----------------------------------------------------------------------
| ROUTER GUARD
|----------------------------------------------------------------------
*/  


router.beforeEach((to, from, next) => {  
  if (to.matched.some(route => route.meta.requiresAuth) && !store.state.USER_AUTH.isLoggedIn) {
       next({ name: 'LoginPage' })
    return
  }
  if(to.path === 'homeBranch'  && store.state.USER_AUTH.isLoggedIn) {
      next({ name: 'home-branch' })  
  } 
  
   if (to.path === '/' && store.state.USER_AUTH.isLoggedIn) {
     next({ name: 'home-branch' })
   } 
    document.title = `Loan - ${to.name}`
     next()
});


/*
|----------------------------------------------------------------------
| GLOBAL HEADER INTERCEPTORS FOR TOKEN & CORS
|----------------------------------------------------------------------
*/ 
axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';

  config.headers.get['Accepts'] = 'application/json';
  config.headers.get['Access-Control-Allow-Origin'] = '*';
  config.headers.get['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
  return config;
});


axios.interceptors.response.use(undefined, function (error) { 
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      localStorage.removeItem('token')
      store.commit('USER_AUTH/LOGOUT_USER')
      router.push({ name: 'LoginPage' })
    }
    return Promise.reject(error)
  }
})

/*
|----------------------------------------------------------------------
| VUE INITIALIZE
|----------------------------------------------------------------------
*/


new Vue({
  el: '#app',
  render: h => h(App),
  store,
  router,
})

