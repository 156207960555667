<template>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light" >
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i>&nbsp;Left Menu</a>
        </li>
      </ul>
  
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" data-widget="navbar-search" href="#" role="button">
            <i class="fas fa-search"></i> Search
          </a>
          <div class="navbar-search-block">
            <form class="form-inline">
              <div class="input-group input-group-sm">
                <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
                <div class="input-group-append">
                  <button class="btn btn-navbar" type="submit">
                    <i class="fas fa-search"></i>
                  </button>
                  <button class="btn btn-navbar" type="button" data-widget="navbar-search">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>
        <template v-if="$store.state.USER_AUTH.user.role_id == '1'">
          <li class="nav-item">
            <a href="javascript:void(0)"  class="nav-link"  @click="changeRoute('admin',45)">
              <i class="fa fa-ban"></i> Admin
            </a>
          </li>
        </template>
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#">
            <i class="fa fa-cog"></i>
            Settings
            <i class="fa fa-caret-down"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item" data-toggle="modal"  v-b-modal.change-password-user>
             <i class="fa fa-lock mr-2"></i> 
             Change Password
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">
            <i class="fa fa-cogs mr-2"></i> 
             Settings
            </a>
            <div class="dropdown-divider"></div>
            <a href="javascript:void(0)" class="dropdown-item"  @click="logoutAction">
              <i class="fa fa-sign-out mr-2"></i> 
               Logout
            </a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-widget="fullscreen" href="#" role="button">
            <i class="fas fa-expand-arrows-alt"></i>
          </a>
        </li>
      </ul>
      <section name="reset-password">
        <b-modal class="modal fade" id="change-password-user" ref="hide-reset-form-modal" hide-footer title="Change Password">
              <form @submit.prevent="resetUserPassword">
                  <div class="form-group">
                      <label> Current Password :</label>
                      <input type="password" v-model="current_password" class="form-control" autocomplete="off">
                  </div>
                  <div class="form-group">
                      <label> New Password :</label>
                      <input type="password" v-model="new_password" class="form-control" autocomplete="off">
                  </div>
                  <div class="form-group">
                      <label> Confirm Password :</label>
                      <input type="password" v-model="confirm_password" class="form-control" autocomplete="off">
                  </div>
                  <br/>
                  <p>Forgot password ? </br><i>Please contact admin for password reset Thank You!.</i></p>
                  <div class="float-right">
                      <button class="btn btn-primary ">Change Password</button>
                 </div>
             </form>
        </b-modal>
     </section>
    </nav>
  </template>
  
  <script>
  export default {
    name: 'AppNavBar',
    data() {
      return {
          current_password:'',
          new_password:'',
          confirm_password:'',
          error_handler:[],
      }
    },

    methods: {
        resetUserPassword(){
          this.$swal({
              title: 'Are you sure?',
              text: 'Do you want to submit this form request',
              type: 'warning',
              showCancelButton: true,
              showCloseButton: false,
          })
        .then((isConfirm) => {
              if(isConfirm === true) {
                       axios.post('api/auth/password',{
                          current_password:this.current_password,
                          new_password:this.new_password,
                          confirm_password:this.confirm_password
                       })
                       .then(resp=> {                      
                              //  this.$swal('Changed', 'Your password has been changed successfully. Please try to login to confirm.' , 'success')
                             
                                this.$swal({
                                    type: 'success',
                                    title: 'Changed',
                                    text: 'Your password has been changed successfully. Please try to login to confirm.',
                                    showConfirmButton: false,
                                    timer: 4000
                               })
                             
                                this.$store.commit('USER_AUTH/LOGOUT_USER')
                                localStorage.removeItem('token')
                                setTimeout(
                                    this.$router.push({ name: 'LoginPage' })
                                ,6000) 
      
                        })    
                        .catch(error => {
                            if (error.response.status == 422){
                                this.error_handler = error.response.data.message
                                console.log(this.error_handler)
                                this.$swal('Opps', this.error_handler, 'error');
                              }
                            console.log('response => ',error.response); 
                        }); 
                }
          });
       },  

      logoutAction(){
          let token = localStorage.getItem("token")
          axios.post('api/logout',{token: this.token})
          .then(resp => {
            console.log(resp)
            if(resp.status == 200){
                 this.$store.commit('USER_AUTH/LOGOUT_USER')
                 localStorage.removeItem('token')
                 setTimeout(
                    this.$router.push({ name: 'LoginPage' })
                 ,6000)
            }
          })
       },
       changeRoute(routeName, selectedIndex) {
            const vm = this

            vm.selectedIndex = selectedIndex

            return vm.$router.push({ name: routeName })
        },
    }
  }
  </script>
