<template>
        <div class="group-repayment-table">
            <!-- Main content -->
            <section class="group-repayment">
            <!-- Default box -->
                <div class="card card-outline card-info row">
                    <div class="card-body">
                        <table id="example1" class="table table-striped">
                        <thead class="table-custom-header">
                        <tr>
                            <th>Name</th>
                            <th>Collection Date</th>
                            <th>Collected By</th>
                            <th>Method</th>
                            <th>Amount</th>
                            <th>Action</th>
                            <th>Receipt</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in items">
                                <td>{{item.name}}</td>
                                <td>{{item.collectionDate}}</td>
                                <td>{{item.collectedBy}}</td>
                                <td>{{item.method}}</td>
                                <td>{{currency(item.amount)}}</td>
                                <td>
                                    <a href="#">Edit</a> &nbsp;
                                    <a href="#">Delete</a>
                                </td>
                                <td style="float: right;">
                                    <button class="btn btn-sm btn-default"><i class="fa fa-print"></i></button>
                                    <button class="btn btn-sm btn-default"><i class="fa fa-file-pdf"></i></button>
                                    <button class="btn btn-sm btn-default"><i class="fa fa-envelope"></i></button>
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            <!-- /.card -->
            </section>
            <!-- /.content -->
        
        </div>
        </template>
        
        <script>
        export default {
           data() {
                return {
                    items:[
                        {
                            id:'1',
                            name:'Mr. john degamo',
                            collectionDate:'08/01/2024',
                            collectedBy:'jayvee ayao',
                            method:'Cash',
                            amount:'13333.33',
                        },
                        {
                            id:'2',
                            name:'Mr. john degamo',
                            collectionDate:'09/02/2024',
                            collectedBy:'jayvee ayao',
                            method:'Cash',
                            amount:'13333.33',
                        }
                    ]
                }
            },
        
            created(){
                //this.borrowerGroupList()
            },
            methods: {
                // async borrowerGroupList(){
                //     let response = await axios.get('api/borrowerGroup')
                //     this.items = response.data
                // },
            },
        }
        </script>