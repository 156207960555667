<template>
    <div class="group-open-loan-table">
        <!-- Main content -->
        <section class="open-loans">
        <!-- Default box -->
            <div class="card card-outline card-info row">
                <div class="card-body">
                    <table id="openLoans" class="table table-striped">
                    <thead class="table-custom-header">
                    <tr>
                        <th>View</th>
                        <th>Released</th>
                        <th>Name</th>
                        <th>Loan#</th>
                        <th>Principal</th>
                        <th>Interest%</th>
                        <th>Due</th>
                        <th>Paid</th>
                        <th>Balance</th>
                        <th>Last Payment</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                         <tr v-for="item in items">
                            <td>
                                <button class="btn btn-default btn-sm"><i class="fa fa-edit"></i></button>&nbsp;
                                <button class="btn btn-default btn-sm"><i class="fa fa-comment"></i></button>
                            </td>
                            <td>{{item.dateReleased}}</td>
                            <td><b>{{item.borrowerName}}</b></td>
                            <td>{{item.loanNumber}}</td>
                            <td>{{currency(item.principal)}}</td>
                            <td>{{item.interest}}</td>
                            <td>{{currency(item.due)}}</td>
                            <td>{{item.paid}}</td>
                            <td>{{currency(item.balance)}}</td>
                            <td>{{item.lastPayment}}</td>
                            <td><button class="btn btn-xs btn-primary">{{item.status}}</button></td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        <!-- /.card -->
        </section>
        <!-- /.content -->
    
    </div>
    </template>
    
    <script>
    export default {
       data() {
            return {
                items:[
                   { 
                     dateReleased:'2024-07-31',
                     borrowerName:'Mr. John Dizon',
                     loanNumber:'17022200',
                     principal:'100000',
                     interest:'5%/Month',
                     due:'160000',
                     paid:'0',
                     balance:'9000',
                     lastPayment:'',
                     status:'Current',
                   },
                ]
            }
        },
    
        created(){
            //this.borrowerGroupList()
            this.items
            this.dt = $('#openLoans').DataTable();
        },
        methods: {
            // async borrowerGroupList(){
            //     let response = await axios.get('api/borrowerGroup')
            //     this.items = response.data
            // },
        },
        watch: {
            items(val) {
                this.dt.destroy();
                this.$nextTick(()=> {
                    this.dt = $('#openLoans').DataTable()
                });
            }
          },
    }
    </script>