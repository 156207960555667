<template>
    <aside class="main-sidebar sidebar-dark-primary" >
  
      <a href="../../index3.html" class="brand-link elevation-4">
        <img src="@/assets/blueprint.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-1" style="opacity: .8">
        <!-- <span class="brand-text font-weight-light"><b>{{$store.state.USER_AUTH.settings.company_name | capitalize}}</b></span> -->
        <span class="brand-text font-weight-light"><b>Loan Management</b></span>
      </a>
  
      <div class="sidebar">
  
        <div class="user-panel mt-3 pb-3 mb-3 d-flex" >
          
               <div class="image">
                  <template v-if="$store.state.USER_AUTH.user.user_image !==''">
                      <img :src="$store.state.USER_AUTH.user.user_image" class="img-circle elevation-2" alt="User Image">
                  </template>   
                  <template v-else>
                      <img src="@/assets/avatar.png" class="img-circle elevation-2" alt="User Image">
                  </template>              
                </div>
        
          <div class="info">
            <a href="#" class="d-block">
               <b>{{$store.state.USER_AUTH.user.staff_first_name | capitalize}} 
                  {{$store.state.USER_AUTH.user.staff_last_name | capitalize}}
                </b>
            </a>
          </div>
        </div>
        <div class="form-inline pb-0">
          <p style="color:#fff" class="ml-4"><i class="nav-icon fas fa-circle"></i> <b>{{$store.state.USER_AUTH.user.branch_name | uppercase }}</b></p>
        </div>
        <nav class="mt-0">
          <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <!-- STATIC MENU -->
            <li class="nav-item">
              <a href="javascript:void(0)"  @click="changeRoute('view-other-branch',46)" :class="[{'active': selectedIndex === 46}, 'nav-link' ]">
                <i class="nav-icon fas fa-eye"></i>
                <p>
                  View Another Branch
                </p>
              </a>
            </li>
            <!-- END STATIC MENU -->
            <!-- DYNAMIC SUB MENU -->
            <template v-for="(item,index) in $store.state.USER_AUTH.permission">
                <li class="nav-item">
                   <!-- Parent menu -->
                   <template v-if="item.main_menu_status == '1'">
                      <a href="javascript:void(0)" :class=" [{'active' :selectedSubIndex === item.category_one_id }, 'nav-link']">
                          <i :class="item.icon"></i>
                          <p>
                            {{item.category_name_one | capitalize}}
                            <i class="right fas fa-angle-left"></i>
                          </p>
                        </a>
                   </template>
                   <template v-if="item.main_menu_status == '2'">
                      <a href="javascript:void(0)"  @click="changeRoute(item.main_component_name,item.category_one_id)" :class="[{'active': selectedIndex === item.category_one_id}, 'nav-link' ]">
                          <i :class="item.icon"></i>
                          <p>
                              {{item.category_name_one | capitalize}}
                          </p>
                        </a>
                   </template>
                   <!-- Child menu -->
                   <template v-if="item.main_menu_status == '1'">
                      <template v-for="(sub_menu,index_sub) in item.categories_one">
                          <ul class="nav nav-treeview">
                            <li class="nav-item" v-if="sub_menu.sub_menu_status == '1'">
                              <a href="javascript:void(0)"  @click="changeRoute(sub_menu.component_name, sub_menu.category_two_id, sub_menu.category_one_id)" :class="[{'active': selectedIndex === sub_menu.category_two_id}, 'nav-link' ]">
                                <i class="far fa-circle nav-icon fa-custom"></i>
                                <p>{{sub_menu.category_name_two | capitalize}}</p>
                              </a>
                            </li>
                          </ul>
                      </template>
                   </template>
                </li>
             </template>
             <!-- END DYNAMIC SUB MENU -->
          </ul>  
        </nav>
      </div>
    </aside>
  </template>
  
  <script>
  export default {
    name: 'AppSideBar',
    data() {
      return {
        selectedIndex: 1,
        selectedSubIndex: 1,
      }
    },

    created(){
       this.$store.dispatch('USER_AUTH/GET_USER')
       this.$store.dispatch('USER_AUTH/GET_SETTINGS')
       this.$store.dispatch('USER_AUTH/GET_PERMISSION')
       this.userCheckPoint()
    },
    methods: {
        changeRoute(routeName, selectedIndex, selectedSubIndex) {
            this.$store.dispatch('USER_AUTH/GET_PERMISSION')
            const vm = this
            vm.selectedIndex = selectedIndex
            vm.selectedSubIndex =  selectedSubIndex
            //console.log(selectedIndex,selectedSubIndex)
            return vm.$router.push({ name: routeName })
        },

         userCheckPoint() {
            axios.get('api/auth/user/permission')
            .then(resp => {
                  let categories = resp.data
                  let component_name = ''
                  let dataCategories = []
                  for (let i = 0; i < categories.length; i++) {
                    for(let t = 0; t < categories[i].categories_one.length; t++ ){
                      component_name = categories[i].categories_one[t].component_name
                       dataCategories.push(component_name)
                    }
                  }

                  // console.log(JSON.stringify(dataCategories))
                  // console.log('response =>',this.$route.name)
                  if(dataCategories.includes(this.$route.name)){
                      //console.log(`${this.$route.name} is in the array.`);
                  }else{
                     this.$router.push({ name: 'home-branch' })
                     //console.log(`${this.$route.name} is not in the array.`);
                  }

                 
            })
        },
    }
  }
  </script>
  
  <style lang="scss">
      .brand-link{
          background-color: #367fa9 !important;
      }
      .fa-custom{
          font-size: 8px !important;
      }
  </style>