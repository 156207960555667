<template>
    <div class="group-schedule-table">
        <!-- Main content -->
        <section class="schedule">
        <!-- Default box -->
        <div class="card card-outline mt-3 card-info">
            <div class="card-body">
                <table id="example1" class="table table-striped">
                <thead class="table-custom-header">
                <tr>
                    <th>#</th>
                    <th>Borrower</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Principal</th>
                    <th>Interest</th>
                    <th>Fees</th>
                    <th>Penalty</th>
                    <th>Due</th>
                    <th>Paid</th>
                    <th>Pending Due</th>
                    <th>Total Due</th>
                    <th>Principal Due</th>
                    <th>Principal Balance</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td>john degamo</td>
                        <td><b>08/01/2024</b></td>
                        <td><b>Today</b></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style="text-align:right">100,00.0</td>
                    </tr>
                    <!-- Main TR-->
                    <tr v-for="(item, index) in items" :key="item.id" :class="{'highlight': index === items.length -1}">
                       <td>{{index + 1}}</td>
                       <td>{{item.borrower}}</td>
                       <td>{{item.date}}</td>
                       <td>{{item.description}}</td>
                       <td style="text-align:right">{{currency(item.principal)}}</td>
                       <td style="text-align:right">{{currency(item.interest)}}</td>
                       <td style="text-align:right">{{currency(item.fees)}}</td>
                       <td style="text-align:right">{{currency(item.penalty)}}</td>
                       <td style="text-align:right">{{currency(item.due)}}</td>
                       <td style="text-align:right">{{currency(item.paid)}}</td>
                       <td style="text-align:right">{{currency(item.pendingDue)}}</td>
                       <td style="text-align:right">{{currency(item.totalDue)}}</td>
                       <td style="text-align:right">{{currency(item.principalDue)}}</td>
                       <td style="text-align:right">{{currency(item.principalBalance)}}</td>
                    </tr>
                    <!-- End TR-->
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><b>Total Due</b></td>
                        <td style="text-align:right">{{currency(TotalPrincipal)}}</td>
                        <td style="text-align:right">{{currency(TotalInterest)}}</td>
                        <td style="text-align:right">{{currency(TotalFees)}}</td>
                        <td style="text-align:right">{{currency(TotalPinalty)}}</td>
                        <td style="text-align:right"><b>{{currency(TotalDue)}}</b></td>
                        <td style="text-align:right"><b>{{currency(TotalPaid)}}</b></td>
                        <td style="text-align:right"><b>{{currency(TotalDue)}}</b></td>
                        <td style="text-align:right"></td>
                        <td style="text-align:right"><b>{{currency(TotalPrincipal)}}</b></td>
                        <td></td>
                    </tr>
                     <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><b>Total Paid</b></td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                     <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><b>Total Pending Due</b></td>
                        <td style="text-align:right">{{currency(TotalPrincipal)}}</td>
                        <td style="text-align:right">{{currency(TotalInterest)}}</td>
                        <td style="text-align:right">{{currency(TotalFees)}}</td>
                        <td style="text-align:right">{{currency(TotalPinalty)}}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
                </table>
            </div>
        </div>
        <!-- <p>No schedule found for open loans in this group</p> -->
        <!-- /.card -->
        </section>
        <!-- /.content -->
    
    </div>
    </template>
    
    <script>
    export default {
       data() {
            return {
                items:[
                    {
                        id:'1',
                        borrower:'john degamo',
                        date:'09/01/2024',
                        description:'Repayment',
                        principal:'8333.33',
                        interest:'5000',
                        fees:'0',
                        penalty:'0',
                        due:'13333.33',
                        paid:'0',
                        pendingDue:'13333.33',
                        totalDue:'13333.33',
                        principalDue:'8333.33',
                        principalBalance:'91666.67'
                    },
                    {
                        id:'2',
                        borrower:'john degamo',
                        date:'10/01/2024',
                        description:'Repayment',
                        principal:'8333.33',
                        interest:'5000',
                        fees:'0',
                        penalty:'0',
                        due:'13333.33',
                        paid:'0',
                        pendingDue:'26666.66',
                        totalDue:'26666.66 ',
                        principalDue:'16666.66',
                        principalBalance:'83333.34'
                    },
                     {
                        id:'3',
                        borrower:'john degamo',
                        date:'11/01/2024',
                        description:'Repayment',
                        principal:'8333.33',
                        interest:'5000',
                        fees:'0',
                        penalty:'0',
                        due:'13333.33',
                        paid:'0',
                        pendingDue:'39999.99',
                        totalDue:'3999999',
                        principalDue:'24999.99',
                        principalBalance:'75000.01 '
                    },
                     {
                        id:'4',
                        borrower:'john degamo',
                        date:'12/01/2024',
                        description:'Repayment',
                        principal:'8333.33',
                        interest:'5000',
                        fees:'0',
                        penalty:'0',
                        due:'13333.33',
                        paid:'0',
                        pendingDue:'53333.32',
                        totalDue:'53333.32',
                        principalDue:'33333.32',
                        principalBalance:'66666.68'
                    },
                     {
                        id:'5',
                        borrower:'john degamo',
                        date:'01/01/2025',
                        description:'Repayment',
                        principal:'8333.33',
                        interest:'5000',
                        fees:'0',
                        penalty:'0',
                        due:'13333.33',
                        paid:'0',
                        pendingDue:'66666.65',
                        totalDue:'66666.65',
                        principalDue:'41666.65',
                        principalBalance:'58333.35'
                    },
                     {
                        id:'6',
                        borrower:'john degamo',
                        date:'02/01/2025',
                        description:'Repayment',
                        principal:'8333.33',
                        interest:'5000',
                        fees:'0',
                        penalty:'0',
                        due:'13333.33',
                        paid:'0',
                        pendingDue:'79999.98',
                        totalDue:'79999.98',
                        principalDue:'49999.98',
                        principalBalance:'50000.02'
                    },
                     {
                        id:'7',
                        borrower:'john degamo',
                        date:'03/01/2025',
                        description:'Repayment',
                        principal:'8333.33',
                        interest:'5000',
                        fees:'0',
                        penalty:'0',
                        due:'13333.33',
                        paid:'0',
                        pendingDue:'93333.31',
                        totalDue:'93333.31',
                        principalDue:'58333.31',
                        principalBalance:'41666.69'
                    },
                     {
                        id:'8',
                        borrower:'john degamo',
                        date:'04/01/2025',
                        description:'Repayment',
                        principal:'8333.33',
                        interest:'5000',
                        fees:'0',
                        penalty:'0',
                        due:'13333.33',
                        paid:'0',
                        pendingDue:'106666.64 ',
                        totalDue:'106666.64',
                        principalDue:'66666.64',
                        principalBalance:'33333.36'
                    },
                     {
                        id:'9',
                        borrower:'john degamo',
                        date:'05/01/2025',
                        description:'Repayment',
                        principal:'8333.33',
                        interest:'5000',
                        fees:'0',
                        penalty:'0',
                        due:'13333.33',
                        paid:'0',
                        pendingDue:'119999.97',
                        totalDue:'119999.97',
                        principalDue:'74999.97',
                        principalBalance:'25000.03'
                    },
                     {
                        id:'10',
                        borrower:'john degamo',
                        date:'06/01/2025',
                        description:'Repayment',
                        principal:'8333.33',
                        interest:'5000',
                        fees:'0',
                        penalty:'0',
                        due:'13333.33',
                        paid:'0',
                        pendingDue:'133333.30',
                        totalDue:'133333.30',
                        principalDue:'83333.30',
                        principalBalance:'16666.70'
                    },
                    {
                        id:'11',
                        borrower:'john degamo',
                        date:'07/01/2025',
                        description:'Repayment',
                        principal:'8333.33',
                        interest:'5000',
                        fees:'0',
                        penalty:'0',
                        due:'13333.33',
                        paid:'0',
                        pendingDue:'146666.63',
                        totalDue:'146666.63',
                        principalDue:'91666.63',
                        principalBalance:'8333.37'
                    },
                    {
                        id:'12',
                        borrower:'john degamo',
                        date:'07/01/2025 ',
                        description:'Maturity',
                        principal:'8333.37',
                        interest:'5000',
                        fees:'0',
                        penalty:'0',
                        due:'13333.37',
                        paid:'0',
                        pendingDue:'160000',
                        totalDue:'160000',
                        principalDue:'100000',
                        principalBalance:'0'
                    }
                
                ]
            }
        },
    
        created(){
            //this.borrowerGroupList()
        },
        methods: {
            // async borrowerGroupList(){
            //     let response = await axios.get('api/borrowerGroup')
            //     this.items = response.data
            // },
        },
        computed: {
          TotalPrincipal: function() {
                let sum = 0;
                this.items.forEach(function(item) {
                  sum += parseFloat(item.principal);
                });
              return sum;
            },

          TotalInterest: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                sum += parseFloat(item.interest);
            });
            return sum;
          },
          TotalFees: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                sum += parseFloat(item.fees);
            });
            return sum;
          },
          TotalPinalty: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                sum += parseFloat(item.penalty);
            });
            return sum;
          },
          TotalDue: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                sum += parseFloat(item.due);
            });
            return sum;
          },
          TotalPaid: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                sum += parseFloat(item.paid);
            });
            return sum;
          },
          TotalPendingDue: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                sum += parseFloat(item.pendingDue);
            });
            return sum;
          },
          TotalPrincipalDue: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                sum += parseFloat(item.principalDue);
            });
            return sum;
          },
        }
    }
 </script>
<style>
  .highlight {
        background-color: pink !important; /* Change to the desired highlight color */
  }
</style>