<template>
    <div class="group-comments-table">
        <!-- Main content -->
        <section class="comments">
             <template v-for="item in items">
               <!-- Sender -->
                <div class="mb-3"></div> 
                <template v-if="item.sender_id == $store.state.USER_AUTH.user.staff_id">
                <div class="image">
                <template v-if="$store.state.USER_AUTH.user.user_image !==''">
                    <img :src="$store.state.USER_AUTH.user.user_image" class="img-circle elevation-2" alt="User Image" width="50"><small> 
                        &nbsp; Me, <b>{{ getDate(item.created_at)}}</b></small> 
                </template>   
                <template v-else>
                    <img src="@/assets/avatar.png" class="img-circle elevation-2" alt="User Image" width="50"><small>
                        &nbsp; Me, <b>{{ getDate(item.created_at)}}</b></small> 
                </template>              
                </div>
                    <!-- Comments-->
                    <div class="mb-1"></div>
                    
                        <a href="#" @click="removeComment(item.id)">Delete</a> |
                        <!-- <a href="#">Edit</a>  -->
                   
                    <b-alert variant="secondary" show>
                        {{item.comment}}
                    </b-alert>
                   
                </template>
                <!-- Receiver -->
                <template v-else>
                        <div class="image">
                            <template v-if="item.staff_picture !==''">
                                <img :src="item.staff_picture" class="img-circle elevation-2" alt="User Image" width="50">
                                <small>&nbsp; {{item.staff_first_name | capitalize}} {{item.staff_last_name | capitalize}}, <b>{{getDate(item.created_at)}}</b></small> 
                            </template>   
                            <template v-else>
                                <img src="@/assets/avatar.png" class="img-circle elevation-2" alt="User Image" width="50">
                                <small>&nbsp; {{item.staff_first_name | capitalize}} {{item.staff_last_name | capitalize}}, <b>{{getDate(item.created_at)}}</b></small> 
                            </template>              
                       </div> 
                      <!-- Comments-->  
                    <div class="mb-3"></div>
                    <b-alert variant="primary" show>
                        {{item.comment}} 
                    </b-alert>
                </template>
             </template>
            <form @submit.prevent="submitComment">
                <template v-if="comment">
                    <label>Write Comment</label>
                    <textarea cols="30" rows="5" class="form-control" v-model="txtComment"></textarea><br/>
                    </template>
                <button v-if="addCommentBtn" class="btn btn-success btn-sm" @click="addComment">Add Comments</button>

                <template v-if="groupComment">
                     <div class="col-md-2 col-12 float-right d-inline-flex p-0">
                        <button class="btn btn-primary btn-sm w-50" @click="closeComment">Close</button> &nbsp;
                        <button type="submit" class="btn btn-primary btn-sm w-50">Comment</button>
                     </div>
                      
                </template>
            </form> 
            
        <!-- /.card -->
        </section>
        <!-- /.content -->
    
    </div>
    </template>
    
    <script>
    export default {
       data() {
            return {
                items:[],
                comment:false,
                addCommentBtn:true,
                groupComment:false,
                txtComment:''

            }
        },
    
        created(){
            this.groupCommentList()
        },
        methods: {
            
            removeComment(id){
                this.$swal({
                    title: 'Are you sure?',
                    text: 'You can\'t revert your action',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes Delete it!',
                    cancelButtonText: 'No, Keep it!',
                    showCloseButton: false,
                    showLoaderOnConfirm: true,
                    })
                    .then((isConfirm) => {
                    if (isConfirm === true) {
                        axios.delete('api/group/comment/remove/' + id)
                            .then(response => {
                                this.groupCommentList() // reloader 
                                this.$swal('Deleted', 'You successfully deleted this record', 'success')
                            })
                            .catch(response => {
                            console.log(response);
                            this.$swal('Error Code', 'Please contact Web developer', 'error')
                            });
                        }
                    }) 
            },
            submitComment(){
                this.$swal({
                title: 'Are you sure?',
                text: 'Do you want to submit this form request',
                type: 'warning',
                showCancelButton: true,
                showCloseButton: false,
              })
             
              .then((isConfirm) => {
                    if(isConfirm === true) {
                       axios.post('api/group/comment', {      
                                group_id: this.$route.params.id,
                                comment: this.txtComment
                              })
                            .then(resp=> {
                              if(resp.data.response == "success"){
                                this.$swal({
                                  type: 'success',
                                  title: 'Sent',
                                  html: 'Your comment has been successfully sent. <br/><br/><br/><br/>',
                                  showConfirmButton: false,
                                  timer: 5000
                                })
                                 setTimeout(() => { 
                                    this.$router.push({ name: 'view-group-details' })
                                    this.txtComment = ''
                                    this.groupCommentList()
                                 }, 3000);
                              }else if(resp.data.response == "error"){
                                  this.$swal('Opps',resp.data.message, 'error')
                              }  
                          })    
                          .catch(error => {
                              if (error.response.status == 422) {
                                    this.error_handler = error.response.data.errors
                              }   
                          }); 
                      }
                });
            },

           
           closeComment(){
               this.comment = false
               this.addCommentBtn = true
               this.groupComment = false   
           },

            addComment(){
               this.comment = true
               this.addCommentBtn = false
               this.groupComment = true      
            },
            async groupCommentList(){
                let response = await axios.get('api/group/comment/'  + this.$route.params.id)
                this.items = response.data
            },
        },
    }
    </script>