const routes = [
  {
    path: '/',
    name: 'LoginPage',
    component: () => import('../views/auth/Login'),
    meta: {
      allowAnonymous: true
    }
  },
  //DASHBOARD PAGES
    {
      path: '/',
      component: () => import('../layouts/DefaultLayout'),
      children: [
          //ADMIN MODULE
          {
            path: 'branch-holidays',
            name: 'branch-holidays',
            component: () => import('../views/pg-admin/branch-holidays'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'branch-settings',
            name: 'branch-settings',
            component: () => import('../views/pg-admin/branch-settings'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'admin',
            name: 'admin',
            component: () => import('../views/pg-admin/admin-menu'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'account-settings',
            name: 'account-settings',
            component: () => import('../views/pg-admin/account-settings'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'permission-edit/:id/permission',
            name: 'permission-edit',
            component: () => import('../views/pg-admin/staff-roles-permission-edit'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'branches-list',
            name: 'branches-list',
            component: () => import('../views/pg-admin/branches-list'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'branches-add',
            name: 'branches-add',
            component: () => import('../views/pg-admin/branches-add'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'branches-edit/:id/find',
            name: 'branches-edit',
            component: () => import('../views/pg-admin/branches-edit'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'branches-equity/:id/find',
            name: 'branches-equity',
            component: () => import('../views/pg-admin/branches-equity'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'branches-capital/:id/create',
            name: 'branches-capital',
            component: () => import('../views/pg-admin/branches-capitals'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'branches-capital-edit/:bid/:id/edit',
            name: 'branches-capital-edit',
            component: () => import('../views/pg-admin/branches-capitals-edit'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'staff-list',
            name: 'staff-list',
            component: () => import('../views/pg-admin/staff-list'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'staff-add',
            name: 'staff-add',
            component: () => import('../views/pg-admin/staff-add'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'staff-edit/:id/find',
            name: 'staff-edit',
            component: () => import('../views/pg-admin/staff-edit'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'staff-roles-permission',
            name: 'staff-roles-permission',
            component: () => import('../views/pg-admin/staff-roles-permission'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'staff-add-role',
            name: 'staff-add-role',
            component: () => import('../views/pg-admin/staff-add-role'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'staff-edit-role/:id/find',
            name: 'staff-edit-role',
            component: () => import('../views/pg-admin/staff-edit-role'),
            meta: {
              requiresAuth: true
            },
          },
          //HOME BRANCH MODULE
          {
            path: 'home-branch',
            name: 'home-branch',
            component: () => import('../views/pg-home-branch/home-branch'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'view-other-branch',
            name: 'view-other-branch',
            component: () => import('../views/pg-home-branch/view-other-branch'),
            meta: {
              requiresAuth: true
            },
          },
          //BORROWERS MODULE
          {
            path: 'view-group-details/:id/details',
            name: 'view-group-details',
            component: () => import('../views/pg-borrowers/view-group-details'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'add-borrower',
            name: 'add-borrower',
            component: () => import('../views/pg-borrowers/add-borrower'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'edit-borrower/:id/find',
            name: 'edit-borrower',
            component: () => import('../views/pg-borrowers/edit-borrower'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'edit-borrower-group/:id/:gid/find',
            name: 'edit-borrower-group',
            component: () => import('../views/pg-borrowers/edit-borrower-group'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'edit-group-members/:id/:gid/find',
            name: 'edit-group-members',
            component: () => import('../views/pg-borrowers/edit-group-members'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'view-borrowers',
            name: 'view-borrowers',
            component: () => import('../views/pg-borrowers/view-borrowers'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'add-borrower-groups',
            name: 'add-borrower-groups',
            component: () => import('../views/pg-borrowers/add-borrower-groups'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'view-borrower-groups',
            name: 'view-borrower-groups',
            component: () => import('../views/pg-borrowers/view-borrower-groups'),
            meta: {
              requiresAuth: true
            },
          },
           //LOAN MODULE
           {
              path: 'add-loans',
              name: 'add-loans',
              component: () => import('../views/pg-loans/add-loans'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'view-loan-products',
              name: 'view-loan-products',
              component: () => import('../views/pg-loans/view-loan-products'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'add-loan-product',
              name: 'add-loan-product',
              component: () => import('../views/pg-loans/add-loan-product'),
              meta: {
                requiresAuth: true
              },
            },
           {
              path: 'approve-loans',
              name: 'approve-loans',
              component: () => import('../views/pg-loans/approve-loans'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'due-loans',
              name: 'due-loans',
              component: () => import('../views/pg-loans/due-loans'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'guarantor',
              name: 'guarantor',
              component: () => import('../views/pg-loans/guarantor'),
              meta: {
                requiresAuth: true
              },
           },
          {
              path: 'add-guarantor',
              name: 'add guarantor',
              component: () => import('../views/pg-loans/add-guarantor'),
              meta: {
                requiresAuth: true
              },
          },
           {
              path: 'loan-calculator',
              name: 'loan-calculator',
              component: () => import('../views/pg-loans/loan-calculator'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'loan-comments',
              name: 'loan-comments',
              component: () => import('../views/pg-loans/loan-comments'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'loans-in-arrears',
              name: 'loans-in-arrears',
              component: () => import('../views/pg-loans/loans-in-arrears'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'missed-repayments',
              name: 'missed-repayments',
              component: () => import('../views/pg-loans/missed-repayments'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'no-repayments',
              name: 'no-repayments',
              component: () => import('../views/pg-loans/no-repayments'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'one-month-late-loans',
              name: 'one-month-late-loans',
              component: () => import('../views/pg-loans/one-month-late-loans'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'pass-maturity-date',
              name: 'pass-maturity-date',
              component: () => import('../views/pg-loans/pass-maturity-date'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'principal-outstanding',
              name: 'principal-outstanding',
              component: () => import('../views/pg-loans/principal-outstanding'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'tree-months-late-loans',
              name: 'tree-months-late-loans',
              component: () => import('../views/pg-loans/three-months-late-loans'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'view-all-loans',
              name: 'view-all-loans',
              component: () => import('../views/pg-loans/view-all-loans'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'view-loan-details/:id',
              name: 'view-loan-details',
              component: () => import('../views/pg-loans/view-loan-details'),
              meta: {
                requiresAuth: true
              },
            },
           {
              path: 'view-loan-disbursed-by',
              name: 'view-loan-disbursed-by',
              component: () => import('../views/pg-loans/view-loan-disbursed-by'),
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'add-loan-disbursed-by',
              name: 'add-loan-disbursed-by',
              component: () => import('../views/pg-loans/add-loan-disbursed-by'),
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'edit-loan-disbursed-by',
              name: 'edit-loan-disbursed-by',
              component: () => import('../views/pg-loans/edit-loan-disbursed-by'),
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'view-repayment-cycles',
              name: 'view-repayment-cycles',
              component: () => import('../views/pg-loans/view-repayment-cycles'),
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'add-repayment-cycle',
              name: 'add-repayment-cycle',
              component: () => import('../views/pg-loans/add-repayment-cycle'),
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'view-loan-fees',
              name: 'view-loan-fees',
              component: () => import('../views/pg-loans/view-loan-fees'),
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'add-loan-fee',
              name: 'add-loan-fee',
              component: () => import('../views/pg-loans/add-loan-fee'),
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'view-loan-status',
              name: 'view-loan-status',
              component: () => import('../views/pg-loans/view-loan-status'),
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'add-loan-status',
              name: 'add-loan-status',
              component: () => import('../views/pg-loans/add-loan-status'),
              meta: {
                requiresAuth: true
              },
            },

           //REPAYMENTS MODULE
           {
              path: 'view-repayments',
              name: 'view-repayments',
              component: () => import('../views/pg-repayments/view-repayments'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'add-bulk-repayments',
              name: 'add-bulk-repayments',
              component: () => import('../views/pg-repayments/add-bulk-repayments'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'upload-repayments',
              name: 'upload-repayments',
              component: () => import('../views/pg-repayments/upload-repayments'),
              meta: {
                requiresAuth: true
              },
           },
           //COLLATERAL REGISTER MODULE
           {
              path: 'collateral-register',
              name: 'collateral-register',
              component: () => import('../views/pg-collateral-register/collateral-register'),
              meta: {
                requiresAuth: true
              },
           },
           //CALENDAR MODULE
           {
              path: 'calendar',
              name: 'calendar',
              component: () => import('../views/pg-calendar/calendar'),
              meta: {
                requiresAuth: true
              },
           },
           //COLLECTION SHEETS MODULE
           {
              path: 'daily-collection-sheet',
              name: 'daily-collection-sheet',
              component: () => import('../views/pg-collection-sheets/daily-collection-sheet'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'missed-repayment-sheet',
              name: 'missed-repayment-sheet',
              component: () => import('../views/pg-collection-sheets/missed-repayment-sheet'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'past-maturity-date-loan',
              name: 'past-maturity-date-loan',
              component: () => import('../views/pg-collection-sheets/past-maturity-date-loan'),
              meta: {
                requiresAuth: true
              },
           },
           //SAVINGS MODULE
           {
              path: 'add-savings-account',
              name: 'add-savings-account',
              component: () => import('../views/pg-savings/add-savings-account'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'cash-safe-management',
              name: 'cash-safe-management',
              component: () => import('../views/pg-savings/cash-safe-management'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'savings-fee-report',
              name: 'savings-fee-report',
              component: () => import('../views/pg-savings/savings-fee-report'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'savings-products-reports',
              name: 'savings-products-reports',
              component: () => import('../views/pg-savings/savings-products-reports'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'savings-report',
              name: 'savings-report',
              component: () => import('../views/pg-savings/savings-report'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'view-savings-account',
              name: 'view-savings-account',
              component: () => import('../views/pg-savings/view-savings-account'),
              meta: {
                requiresAuth: true
              },
           },
           //SAVINGS TRANSACTION MODULE
           {
              path: 'approve-transactions',
              name: 'approve-transactions',
              component: () => import('../views/pg-savings-transaction/approve-transactions'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'staff-transaction-report',
              name: 'staff-transaction-report',
              component: () => import('../views/pg-savings-transaction/staff-transaction-report'),
              meta: {
                requiresAuth: true
              },
           },
           {
              path: 'upload-transactions',
              name: 'upload-transactions',
              component: () => import('../views/pg-savings-transaction/upload-transactions'),
              meta: {
                requiresAuth: true
              },
           },
           //INVESTOR MODULE
           {
              path: 'add-investor',
              name: 'add-investor',
              component: () => import('../views/pg-investor/add-investor'),
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'send-email-to-investor',
              name: 'send-email-to-investor',
              component: () => import('../views/pg-investor/send-email-to-investor'),
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'send-sms-to-investor',
              name: 'send-sms-to-investor',
              component: () => import('../views/pg-investor/send-sms-to-investor'),
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'view-investor',
              name: 'view-investor',
              component: () => import('../views/pg-investor/view-investor'),
              meta: {
                requiresAuth: true
              },
            },
           //ASSET MANAGEMENT MODULE
            {
              path: 'view-asset-management',
              name: 'view-asset-management',
              component: () => import('../views/pg-asset-managment/view-asset-management'),
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'add-asset-management',
              name: 'add-asset-management',
              component: () => import('../views/pg-asset-managment/add-asset-management'),
              meta: {
                requiresAuth: true
              },
            },
            //REPORTS MODULE
      ]
    },
  //PAGE NOT FOUND
  {
    path:'*',
    component: () => import('../views/NotFound'),
    meta: {
      allowAnonymous: true
    },
  }
]
export default routes
